.missing-metadata {
  animation: glowBorder 2.5s ease-in-out infinite alternate;
}
.missing-metadata-text {
  /* animation: glowText 1s ease-in-out infinite alternate; */
  text-shadow: 0 0 1px #fff, 0 0 2px #e9821e, 0 0 3px #e9821e, 0 0 4px #e9821e;
}

@keyframes glowBorder {
  from {
    box-shadow: 0 0 1px 1px #fff, 0 0 2px 2px #e9821e;
  }
  to {
    box-shadow: 0 0 2px 2px #e9821e, 0 0 1px 1px #fff;
  }
}

@keyframes glowText {
  from {
    text-shadow: 0 0 1px #fff, 0 0 2px #e9821e, 0 0 3px #e9821e, 0 0 4px #e9821e;
  }
  to {
    text-shadow: 0 0 1px #e9821e 0 0 2px #e9821e, 0 0 3px #e9821e, 0 0 2px #fff;
  }
}
