.ql {
  overflow: auto;
}
.ql-editor {
  min-height: 6rem;
}
.ql-tooltip {
  left: 0 !important;
}

.ql-editor li.ql-indent-1:before {
  content: '• ';
}

.ql-editor li.ql-indent-2:before {
  content: '◦ ';
}

.ql-editor li.ql-indent-3:before {
  content: '▪ ';
}

.ql-editor li.ql-indent-4:before {
  content: '▫ ';
}

.ql-editor li.ql-indent-5:before {
  content: '▪ ';
}

.ql-editor li.ql-indent-6:before {
  content: '▫ ';
}

.ql-editor li.ql-indent-7:before {
  content: '▪ ';
}

.ql-editor li.ql-indent-8:before {
  content: '▫ ';
}

.ql-editor li.ql-indent-9:before {
  content: '▪ ';
}
