.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker__close-icon::after {
  background-color: rgb(156 163 175);
  width: 20px;
  height: 20px;
  font-size: 18px;
  font-weight: 700;
}

.react-datepicker__close-icon:hover:after {
  background-color: rgb(239 68 68);
  width: 20px;
  height: 20px;
  font-size: 18px;
  font-weight: 700;
}
