.tooltip {
  background-color: #e6e7ea !important;
  padding: 12px !important;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.12) !important;
  border-radius: 2px !important;
  opacity: 1 !important;
}

.tooltip.type-dark.place-top:after {
  border-top-color: #e6e7ea !important;
}
.tooltip.type-dark.place-right:after {
  border-right-color: #e6e7ea !important;
}
.tooltip.type-dark.place-bottom:after {
  border-bottom-color: #e6e7ea !important;
}
.tooltip.type-dark.place-left:after {
  border-left-color: #e6e7ea !important;
}
