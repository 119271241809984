@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  font-family: 'Nunito', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.heading {
    font-family: 'Barlow Condensed', 'Roboto',  sans-serif;
}

.html ul,
.html ol {
  display: block;
  list-style: disc outside none;
  margin: 1em 0;
  padding: 0 0 0 40px;
}
.html ol {
  list-style: decimal;
}

.horizontal-scrollbox {
  overflow-x: auto;
  /* background-image: linear-gradient(to right, white, white),
    linear-gradient(to right, white, white),
    linear-gradient(to right, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0)),
    linear-gradient(to left, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0));

  background-position: left center, right center, left center, right center;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100%;

  background-attachment: local, local, scroll, scroll; */
}
.vertical-scrollbox {
  overflow-y: auto;
}

#root {
  min-height: 100vh;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 2.25rem;
}
h2 {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
}
h3 {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.75rem;
}
h4 {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.75rem;
}

a {
  margin: 0;
  padding: 0;
  text-decoration: none;
  color: #065591;
  word-break: break-all;
}

.rotate-chevron,
.rotate-caret {
  transition: all 200ms linear;
}

.rotate-chevron.down {
  transform: rotate(-180deg);
}
.rotate-caret.down {
  transform: rotate(180deg);
}

.Toastify__toast {
  border-radius: 4px !important;
  padding: 0 !important;
  min-height: auto !important;
}
.Toastify__toast-body {
  margin: 0 !important;
}
.Toastify__toast--success {
  background: #3cb878 !important;
}
.Toastify__toast--error {
  background: #ed1c24 !important;
}

.collapse-css-transition {
  transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden;
}

.plus-minus.plus,
.plus-minus.minus {
  transition: all 200ms ease-in-out;
}

.plus-minus.plus.closed {
  opacity: 1;
  transform: rotate(0);
}
.plus-minus.plus.open {
  opacity: 0;
  transform: rotate(90deg);
}

.plus-minus.minus.closed {
  opacity: 0;
  transform: rotate(-90deg);
}
.plus-minus.minus.open {
  opacity: 1;
  transform: rotate(0);
}
