@media print {
  @page {
    size: landscape;
  }
  body {
    margin: 0;
    background-color: #fff;
  }
  #breadcrumbs {
    display: none;
  }
  .screen {
    padding: 0 !important;
    background-color: #fff;
  }
  .paper {
    box-shadow: none !important;
    margin: 4px !important;
    border: solid 1px #333;
    padding: 4px !important;
  }
  .hide-on-print {
    visibility: collapse;
  }

  .print-hr {
    page-break-after: always;
  }

  .avoid-print-break {
    break-inside: avoid;
  }
}
