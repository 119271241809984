@page {
  size: A4;
}
@page :left {
  margin-left: 0.5cm;
}
@page :right {
  margin-left: 0.5cm;
}

table,
figure {
  page-break-inside: avoid;
  border-collapse: collapse;
}

table td {
  padding: 0;
  margin: 0;
}

table td:first-child,
table td:last-child {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

#print-page p {
  font-size: 0.75rem;
}
