#list-filter {
  overflow-x: hidden;
  position: relative;
  transition: width 0.2s linear;
}

#list-filter.open {
  width: 250px;
  box-shadow: 4px 0 4px -2px rgba(0, 0, 0, 0.12);
}
#list-filter.closed {
  width: 48px;
}
#list-filter.hidden {
  width: 0px;
}

#list-filter .collapsible {
  visibility: visible;
  transition: all 0.1s linear;
}
#list-filter.open .collapsible.open {
  opacity: 1;
}
#list-filter.closed .collapsible.closed {
  opacity: 0;
  width: 0;
}
