#drawer {
  z-index: 3;
  overflow-x: hidden;
  transition: 0.5s;
}
#drawer.open {
  width: 250px;
  box-shadow: 5px 0 10px 0 rgba(0, 0, 0, 0.2);
}
#drawer.closed {
  width: 48px;
}
#drawer.hide {
  width: 0px;
}
#drawer.over {
  width: 250px;
  box-shadow: 18px 0 48px rgba(0, 0, 0, 0.25);
}

#main {
  transition: margin-left 0.5s, width 0.5s, max-width 0.5s;
  max-width: 100vw;
  width: 100%;
}
#main.open {
  margin-left: 250px;
  max-width: calc(100% - 250px);
}
#main.closed {
  margin-left: 48px;
  max-width: calc(100% - 48px);
}
#main.over {
  /* background-color: rgba(0, 0, 0, 0.5); */
}

#drawer .collapsible.open {
  transition: all 0.5s;
  transition-delay: 0.3s;
  opacity: 1;
}
#drawer .collapsible.closed {
  transition: all 0.2s;
  opacity: 0;
}
