.switch,
.switch-label,
.switch-icon,
.switch:before,
.switch-label:before,
.switch-icon:before,
.switch:after,
.switch-label:after,
.switch-icon:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  transition: 0.25s ease-in-out;
  outline: none;
}
.switch-icon {
  transition: 0.25s ease-in-out;
  transition-delay: 0.25s;
}
.switch-container {
  position: relative;
  width: 70px;
  height: 36px;
}
.switch-container.small {
  width: 58px;
  height: 28px;
}
.switch,
.switch:active {
  position: absolute;
  top: -5000px;
  height: 0;
  width: 0;
  opacity: 0;
  border: none;
  outline: none;
}
.switch-label {
  display: block;
  position: relative;
  font-size: 12px;
  line-height: 16px;
  width: 100%;
  height: 36px;
  min-height: 36px;
  border-radius: 18px;
  background: #b7b7b7;
  cursor: pointer;
}
.switch-label.small {
  height: 28px;
  min-height: 28px;
}
.switch-label.locked {
  cursor: not-allowed;
}
.switch-label:before {
  content: '';
  display: block;
  position: absolute;
  z-index: 1;
  line-height: 34px;
  text-indent: 40px;
  height: 36px;
  min-height: 36px;
  width: 36px;
  border-radius: 100%;
  top: 0px;
  left: 0px;
  right: auto;
  background: white;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2), 0 0 0 2px #dddddd;
}
.switch-label.small:before {
  width: 28px;
  height: 28px;
  min-height: 28px;
}

/* Checked */
.switch-container.primary .switch:checked + .switch-label {
  box-shadow: inset 0 0 0 20px #448eca, 0 0 0 2px #448eca;
}
.switch-container.secondary .switch:checked + .switch-label {
  box-shadow: inset 0 0 0 20px #f5730c, 0 0 0 2px #f5730c;
}
.switch-container.success .switch:checked + .switch-label {
  box-shadow: inset 0 0 0 20px #00a651, 0 0 0 2px #00a651;
}
.switch:checked + .switch-label:before {
  left: calc(100% - 36px);
  box-shadow: 0 0 0 2px transparent, 0 3px 3px rgba(0, 0, 0, 0.3);
}
.switch.small:checked + .switch-label.small:before {
  left: calc(100% - 28px);
  box-shadow: 0 0 0 2px transparent, 0 3px 3px rgba(0, 0, 0, 0.3);
}
.switch-icon {
  position: absolute;
  color: #fff;
}
.switch-icon.on {
  left: 12px;
  top: 6px;
}
.switch-icon.off {
  right: 8px;
  top: 6px;
}
.switch-icon.on.small {
  top: 6px;
}
.switch-icon.off.small {
  top: 6px;
}

.switch-text {
  position: absolute;
  color: #fff;
  user-select: none;
}
.switch-text.on {
  left: 8px;
  top: 8px;
}
.switch-text.off {
  right: 10px;
  top: 8px;
}

.switch-lock {
  position: absolute;
  color: #000;
  z-index: 1;
}
.switch-lock.on {
  right: 6px;
  top: 6px;
}
.switch-lock.off {
  left: 6px;
  top: 6px;
}
