#notification-center {
  z-index: 3;
  overflow-x: hidden;
  transition: 0.25s;
}

#notification-center.closed {
  width: 0px;
}
#notification-center.open {
  width: 420px;
  box-shadow: 18px 0 48px rgba(0, 0, 0, 0.25);
}

@media (max-width: 768px) {
  #notification-center.open {
    width: 320px;
  }
}

#notification-center.open #notification-center-content {
  transition: 0.25s;
  transition-delay: 0.25s;
  opacity: 1;
}
#notification-center.closed #notification-center-content {
  transition: 0.1s;
  transition-delay: 0s;
  opacity: 0;
}
